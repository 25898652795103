import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { FormElements, NewDatePicker, NewDialog, TimezoneGMT, TIMEZONES_GMT_NAMES } from 'web-components';
import { Box, FormGroup, Stack, TextField, Button } from '@mui/material';
import DateTimeProvider from 'components/DateTime/DateTimeProvider';
import { TimePicker } from '@mui/x-date-pickers';
import { FileFormats, FileFormatsGraphTemplates } from '../../helpers/consts';

const FILTERED_OUT_TIMEZONES = [TIMEZONES_GMT_NAMES.UNKNOWN];

const timezones = TimezoneGMT.filter(({ value }) => !FILTERED_OUT_TIMEZONES.includes(value));

const periods = [
  { key: '5s', value: '5 s' },
  { key: '30s', value: '30 s' },
  { key: '1m0s', value: '1 min' },
  { key: '1h0m0s', value: '1 h' },
  { key: '24h0m0s', value: '1 d' }
];

/**
 * Custom dialog to select a date range and time range
 * ATTENTION: This is implemented locally because systems use diferent date providers
 * @param open<caption>Define if the Dialog will be open
 * @param handleClose<caption>Handle close method for default actions
 * @param title<caption>Title of Dialog
 * @param subTitle<caption>Subtitle of Dialog
 * @param startTimeCaption<caption>Start time caption
 * @param endTimeCaption<caption>End time caption
 * @param cancelCaption<caption>Cancel button caption
 * @param okCaption<caption>Save button caption
 * @param setValues<caption>Set values got from the dialog
 * @param handleApply<caption>Extra actions to be executed when user clicks on apply date
 * @param showDatePicker<caption>Can pass false to hide Calendar picker
 * @param showTimePicker<caption>Can pass false to hide time pickers
 * @returns CustomDateDialog<caption>@type {NewDialog}
 */
function DateExportDialog({
  isMachineDetail,
  open,
  handleClose,
  title,
  subTitle,
  startTimeCaption,
  endTimeCaption,
  cancelCaption,
  okCaption,
  setValues,
  handleApply,
  showDatePicker,
  showTimePicker,
  maxDays,
  maxWidth
}) {
  const { t } = useTranslation();

  const [startDate, setStartDate] = useState(null);
  const [isInvalidStartDate, setIsInvalidStartDate] = useState(false);
  const [endDate, setEndDate] = useState(null);
  const [isInvalidEndDate, setIsInvalidEndDate] = useState(false);

  const [startTime, setStartTime] = useState(null);
  const [isInvalidStartTime, setIsInvalidStartTime] = useState(false);
  const [endTime, setEndTime] = useState(null);
  const [isInvalidEndTime, setIsInvalidEndTime] = useState(false);

  const [fileFormat, setFileFormat] = useState([]);
  const [samplePeriod, setSamplePeriod] = useState('5s');
  const [timeZone, setTimeZone] = useState([]);

  const handleDateChange = value => {
    setStartDate(value?.startDate);
    if (value?.startDate && value?.startDate.isValid()) {
      setIsInvalidStartDate(false);
    } else {
      setIsInvalidStartDate(true);
    }

    setEndDate(value?.endDate);
    if (value?.endDate && value?.endDate.isValid()) {
      setIsInvalidEndDate(false);
    } else {
      setIsInvalidEndDate(true);
    }
  };

  const handleStartTime = date => {
    setStartTime(date);
    if (date && date.isValid()) {
      setIsInvalidStartTime(false);
    } else {
      setIsInvalidStartTime(true);
    }
  };

  const handleEndTime = date => {
    setEndTime(date);
    if (date && date.isValid()) {
      setIsInvalidEndTime(false);
    } else {
      setIsInvalidEndTime(true);
    }
  };

  function isValidDateRange() {
    return startDate && !isInvalidStartDate && endDate && !isInvalidEndDate;
  }

  function isValidTimeRange() {
    return showTimePicker ? startTime && !isInvalidStartTime && endTime && !isInvalidEndTime : true;
  }

  function canSave() {
    if (!showDatePicker || !showTimePicker) {
      return fileFormat.length > 0 && timeZone.length > 0;
    }

    const areFieldsFilled = fileFormat.length > 0 && timeZone.length > 0;

    const isDateRangeValid = isValidDateRange();
    const isTimeRangeValid = isValidTimeRange();

    return areFieldsFilled && isDateRangeValid && isTimeRangeValid;
  }

  const handleOk = () => {
    let newStartDateTimeText = '';
    let newFinalDateTimeText = '';
    if (showDatePicker) {
      const newStartTime = startTime && startTime.isValid() ? startTime.format('HH:mm') : '00:00';
      const newStartDateTime = new Date(`${startDate.format('YYYY-MM-DD')}T${newStartTime}`);
      newStartDateTimeText = newStartDateTime.toISOString();

      const newEndTime = endTime && endTime.isValid() ? endTime.format('HH:mm') : '23:59';
      const newFinalDateTime = new Date(`${endDate.format('YYYY-MM-DD')}T${newEndTime}`);
      newFinalDateTimeText = newFinalDateTime.toISOString();
    }

    setValues(newStartDateTimeText, newFinalDateTimeText, fileFormat, samplePeriod, timeZone);

    handleApply();
    handleClose();
  };

  const actionBar = (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column-reverse', sm: 'row' },
          gap: 2,
          width: '100%'
        }}
      >
        <Button
          variant="text"
          color="secondary"
          onClick={() => handleClose()}
          sx={{ width: '100%', height: '56px', padding: '16px' }}
        >
          {cancelCaption}
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={() => handleOk()}
          disabled={!canSave()}
          sx={{ width: '100%', height: '56px', padding: '16px' }}
        >
          {okCaption}
        </Button>
      </Box>
    </>
  );

  const content = (
    <>
      <Box
        sx={{
          maxWidth: 'sm',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          width: '100%',
          mx: 'auto',
          '& > * + *': {
            mt: 2
          }
        }}
      >
        <DateTimeProvider>
          <>
            {showDatePicker && <NewDatePicker HandleDates={handleDateChange} maxDays={maxDays} />}
            {showTimePicker && (
              <Stack direction="row" spacing={2}>
                <TimePicker
                  id="start-time-picker"
                  label={startTimeCaption}
                  ampm={false}
                  format="HH:mm"
                  value={startTime}
                  onChange={handleStartTime}
                  slotProps={{
                    textField: {
                      variant: 'filled'
                    }
                  }}
                  sx={{ maxWidth: '180px' }}
                  renderInput={params => <TextField {...params} error={isInvalidStartTime} />}
                />
                <TimePicker
                  id="end-time-picker"
                  label={endTimeCaption}
                  ampm={false}
                  format="HH:mm"
                  value={endTime}
                  onChange={handleEndTime}
                  slotProps={{
                    textField: {
                      variant: 'filled'
                    }
                  }}
                  sx={{ maxWidth: '180px' }}
                  renderInput={params => <TextField {...params} error={isInvalidEndTime} />}
                />
              </Stack>
            )}
          </>
        </DateTimeProvider>
      </Box>
      <FormGroup row>
        <FormElements.Select
          label={t('dialog.data_exports.select_file_format')}
          id="file-format"
          name="file-format"
          handleChange={e => setFileFormat(e.target.value)}
          value={fileFormat}
          data-selector=""
          fullWidth
        >
          <option />
          {(isMachineDetail ? FileFormats : FileFormatsGraphTemplates).map(file => (
            <option key={file.key} value={file.value}>
              {file.name}
            </option>
          ))}
        </FormElements.Select>
      </FormGroup>
      <FormGroup row>
        <FormElements.Select
          label={t('dialog.data_exports.sample_period')}
          id="sample-period"
          name="sample-period"
          handleChange={e => setSamplePeriod(e.target.value)}
          value={samplePeriod}
          data-selector=""
          fullWidth
          disabled={fileFormat === 'pdf'}
        >
          {periods.map(period => (
            <option key={period.key} value={period.key}>
              {period.value}
            </option>
          ))}
        </FormElements.Select>
      </FormGroup>
      <FormGroup row>
        <FormElements.Select
          label={t('dialog.data_exports.timezone')}
          id="timezone"
          name="timezone"
          handleChange={e => setTimeZone(e.target.value)}
          value={timeZone}
          data-selector=""
          fullWidth
        >
          <option />
          {timezones.map(gmt => (
            <option key={gmt.value} value={gmt.value}>
              {gmt.name}
            </option>
          ))}
        </FormElements.Select>
      </FormGroup>
    </>
  );

  return (
    <NewDialog
      open={open}
      handleClose={handleClose}
      title={title}
      subTitle={subTitle}
      content={content}
      displayActionBar="flex"
      justifyContentActionBar="flex-end"
      maxWidth={maxWidth}
      actionBar={actionBar}
    />
  );
}

DateExportDialog.propTypes = {
  isMachineDetail: PropTypes.bool,
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  subTitle: PropTypes.string,
  startTimeCaption: PropTypes.string.isRequired,
  endTimeCaption: PropTypes.string.isRequired,
  cancelCaption: PropTypes.string.isRequired,
  okCaption: PropTypes.string.isRequired,
  setValues: PropTypes.func.isRequired,
  handleApply: PropTypes.func,
  showDatePicker: PropTypes.bool,
  showTimePicker: PropTypes.bool,
  maxDays: PropTypes.number,
  isDataMachineExport: PropTypes.bool.isRequired,
  maxWidth: PropTypes.string
};

DateExportDialog.defaultProps = {
  isMachineDetail: false,
  subTitle: '',
  handleApply: () => {},
  showDatePicker: false,
  showTimePicker: true,
  maxDays: 0,
  maxWidth: '688px'
};

export default DateExportDialog;
