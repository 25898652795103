import React from 'react';
import T from 'prop-types';
import { Box, Step, Stepper } from '@mui/material';
import { CustomStepLabel } from '../AddNewTemplate.Styled';
import { StepIconComponent } from '../../StepIconComponent';

const Steps = ({ activeStep, steps }) => (
  <Box
    sx={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      m: '42px auto'
    }}
  >
    <Stepper activeStep={activeStep} sx={{ width: '200px' }}>
      {steps.map((label, index) => (
        <Step key={label}>
          <CustomStepLabel
            active={index === activeStep}
            completed={index < activeStep}
            StepIconComponent={props => StepIconComponent({ ...props, icon: index + 1 })}
          />
        </Step>
      ))}
    </Stepper>
  </Box>
);

export { Steps };

Steps.propTypes = {
  activeStep: T.number.isRequired,
  steps: T.arrayOf(T.string).isRequired
};
