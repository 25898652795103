import React from 'react';
import T from 'prop-types';
import { Box, FormControl, FormControlLabel, FormLabel, Grid, InputAdornment, Radio, RadioGroup } from '@mui/material';
import { FormElements, NotifyIcon } from 'web-components';
import { useTranslation } from 'react-i18next';

const Step2 = ({
  handleInputChange,
  templateIdentification,
  errors,
  filterTime,
  setFilterTime,
  periodObject,
  description,
  accessType,
  setAccessType
}) => {
  const { t } = useTranslation();

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <FormElements.TextField
            label={t('dialog.add_new_template.template_identification')}
            size="medium"
            name="template_identification"
            id="template_identification"
            required
            fullWidth
            bgcolor="#F7F7FA"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end" style={{ position: 'absolute', right: '12px', bottom: '22px' }}>
                  <NotifyIcon iconName="filterOutlined" fontSize="default" color="primary" />
                </InputAdornment>
              )
            }}
            onChange={e => {
              handleInputChange('templateIdentification', e.target.value);
            }}
            value={templateIdentification}
            error={!!errors.templateIdentification}
            helperText={errors.templateIdentification}
            data-selector="batch-note-modal-title-field"
          />
        </Grid>
        <Grid item xs={6}>
          <Box style={{ marginTop: '-16px' }}>
            <FormElements.Select
              name="period_time"
              id="period_time"
              required
              fullWidth
              value={filterTime}
              onChange={e => setFilterTime(e.target.value)}
              label={t('dialog.add_new_template.filter_time')}
            >
              {periodObject.map(ftime => (
                <option key={ftime.label} value={ftime.value}>
                  {`${t(ftime.label)}`}
                </option>
              ))}
            </FormElements.Select>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <FormElements.TextField
            className="subject"
            label={t('dialog.add_new_template.description')}
            id="descrption"
            required
            margin="normal"
            name="note"
            multiline
            rows={6}
            fullWidth
            onChange={e => {
              handleInputChange('description', e.target.value);
            }}
            value={description}
            error={!!errors.description}
            helperText={errors.description}
            data-selector="dialog-add-new-template-desciption-field"
            sx={{
              mt: { md: 1 },
              mb: { md: '24px' }
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <FormControl component="fieldset">
            <FormLabel>{t('dialog.add_new_template.your_template')}</FormLabel>
            <RadioGroup
              id="accessType"
              defaultValue="ACCESS_TYPE_USER_ONLY"
              value={accessType}
              onChange={e => setAccessType(e.target.value)}
              data-selector="dialog-add-new-template-access_type-field"
            >
              <FormControlLabel
                value="ACCESS_TYPE_USER_ONLY"
                control={<Radio />}
                label={t('dialog.add_new_template.access_type.only_me')}
                sx={{ marginBottom: '20px' }}
              />
              <FormControlLabel
                value="ACCESS_TYPE_ALL_ORG"
                control={<Radio />}
                label={t('dialog.add_new_template.access_type.all_users')}
              />
            </RadioGroup>
          </FormControl>
        </Grid>
      </Grid>
    </>
  );
};

export { Step2 };

Step2.propTypes = {
  handleInputChange: T.func.isRequired,
  templateIdentification: T.string.isRequired,
  errors: T.shape({
    templateIdentification: T.string,
    description: T.string
  }).isRequired,
  filterTime: T.string.isRequired,
  setFilterTime: T.func.isRequired,
  periodObject: T.arrayOf(
    T.shape({
      label: T.string,
      value: T.string
    })
  ).isRequired,
  description: T.string.isRequired,
  accessType: T.string.isRequired,
  setAccessType: T.func.isRequired
};
