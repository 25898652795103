import React from 'react';
import T from 'prop-types';
import { Button, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';

const ActionBar = ({ handleCloseDialog, activeStep, handleBack, steps, handleSave, handleNext }) => {
  const { t } = useTranslation();

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={12} md={6}>
        <Button
          variant="text"
          color="secondary"
          onClick={handleCloseDialog}
          sx={{ height: '56px', width: { sm: '100%', md: '90px' } }}
        >
          {t('default_actions.cancel')}
        </Button>
      </Grid>
      <Grid item xs={12} sm={12} md={6} style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Grid container spacing={1} justifyContent="flex-end">
          <Grid item xs={12} sm={12} md={3} style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button
              variant="outlined"
              color="primary"
              disabled={activeStep === 0}
              onClick={handleBack}
              sx={{ height: '56px', width: { sm: '100%', md: '127px' } }}
            >
              {t('default_actions.back')}
            </Button>
          </Grid>
          {activeStep === steps.length - 1 ? (
            <Grid item xs={12} sm={12} md={3} style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button
                variant="contained"
                color="primary"
                disabled={activeStep === 0}
                onClick={handleSave}
                sx={{ height: '56px', width: { sm: '100%', md: '127px' } }}
              >
                {t('default_actions.save')}
              </Button>
            </Grid>
          ) : (
            <Grid item xs={12} sm={12} md={3} style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button
                variant="outlined"
                color="primary"
                sx={{ height: '56px', width: { sm: '100%', md: '127px' } }}
                onClick={handleNext}
              >
                {t('default_actions.next')}
              </Button>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

export { ActionBar };

ActionBar.propTypes = {
  handleCloseDialog: T.func.isRequired,
  activeStep: T.number.isRequired,
  handleBack: T.func.isRequired,
  steps: T.arrayOf(T.string).isRequired,
  handleSave: T.func.isRequired,
  handleNext: T.func.isRequired
};
