import React, { useEffect, useState } from 'react';
import T from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Alert, Box, Grid } from '@mui/material';
import { NewDialog } from 'web-components';
import { compose } from 'redux';
import { getData } from '../../../../../redux/rootSelectors';
import {
  loadMachines,
  loadMachinesSensorsTreeView,
  loadProductionsLines,
  loadSensors
} from '../../../../../redux/filters/actions';
import {
  getMachineSensorsTreeView,
  getMachinesList,
  getProductionLines,
  getSensors
} from '../../../../../redux/filters/selectors';
import { createGraphsTemplate } from '../../../../../redux/graphTemplates/actions';
import { periodObject } from '../../../../../helpers/periodObject';
import { generateColor } from '../../../../../helpers/colorUtils';
import { Steps } from './components/Steps';
import { Step1 } from './components/Step1';
import { Step2 } from './components/Step2';
import { ActionBar } from './components/ActionBar';

const steps = ['Step1', 'Step2'];

export function AddNewTemplate({ machine: machineParam, open, handleCloseDialog }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [activeStep, setActiveStep] = useState(0);
  const productionLinesObj = useSelector(compose(getData, getProductionLines));
  const treeView = useSelector(compose(getData, getMachineSensorsTreeView));
  const sensors = useSelector(compose(getData, getSensors));
  const machines = useSelector(compose(getData, getMachinesList));

  const [selectedProductLines, setSelectedProductLines] = useState([]);
  const [selectedMachines, setSelectedMachines] = useState([]);
  const [selectedSensors, setSelectedSensors] = useState([]);
  const [filteredTreeData, setFilteredTreeData] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [templateIdentification, setTemplateIdentification] = useState('');
  const [filterTime, setFilterTime] = useState(periodObject[0].value);
  const [description, setDescription] = useState('');
  const [accessType, setAccessType] = useState('ACCESS_TYPE_USER_ONLY');
  const [clear, setClear] = useState(false);
  const [alertSelectSensors, setAlertSelectSensors] = useState(false);
  const [errors, setErrors] = useState({
    templateIdentification: '',
    description: ''
  });

  const resetAllStates = () => {
    setActiveStep(0);
    setFilteredTreeData(treeView?.production_lines || []);
    setSelectedProductLines([]);
    setSelectedMachines([]);
    setSelectedSensors([]);
    setSelectedItems([]);
    setTemplateIdentification('');
    setFilterTime(periodObject[0].value);
    setDescription('');
    setAccessType('ACCESS_TYPE_USER_ONLY');
    setClear(false);
    setErrors({});
    setAlertSelectSensors(false);
  };

  useEffect(() => {
    if (!open) {
      resetAllStates();
    }
  }, [open]);

  useEffect(() => {
    if (open && !!machineParam?.id && !selectedProductLines?.length && !selectedMachines?.length) {
      const preDefProdLineFilter = [{ title: machineParam.production_line, value: machineParam.production_line }];
      const preDefMachineFilter = [{ id: machineParam.id, title: machineParam.name, value: machineParam.name }];
      setSelectedProductLines(preDefProdLineFilter);
      setSelectedMachines(preDefMachineFilter);
    }
  }, [open, machineParam]);

  useEffect(() => {
    if (selectedProductLines.length || selectedMachines.length || selectedSensors.length) {
      const params = {
        productionLines: selectedProductLines.map(line => line.value),
        sensors: selectedSensors.map(sensor => sensor.value),
        machineIds: selectedMachines.map(machine => machine.id)
      };
      dispatch(loadMachinesSensorsTreeView(params));
      dispatch(loadProductionsLines(params));
      dispatch(loadMachines(params));
    }
  }, [dispatch, selectedProductLines, selectedSensors, selectedMachines]);

  useEffect(() => {
    if (!selectedProductLines.length && !selectedMachines.length) {
      dispatch(loadSensors({ productionLines: [], machineIds: [] }));
    }
  }, [dispatch]);

  useEffect(() => {
    const searchTermsArray = [...selectedProductLines, ...selectedMachines, ...selectedSensors].map(item => item.title);

    const searchTree = (nodes, searchTerms) => {
      if (searchTerms.length === 0) return nodes;

      return nodes.reduce((acc, node) => {
        const isLineMatch = searchTerms.some(term => node.name.toLowerCase() === term.toLowerCase());

        let matchingMachines = [];
        if (node.machines) {
          matchingMachines = node.machines.reduce((machinesAcc, machine) => {
            const isMachineMatch = searchTerms.some(term => machine.name.toLowerCase() === term.toLowerCase());

            let matchingSensors = [];
            if (machine.sensors) {
              matchingSensors = machine.sensors.filter(sensor =>
                searchTerms.some(
                  term =>
                    sensor.custom_sensor_name?.toLowerCase().includes(term.toLowerCase()) ||
                    sensor.type.toLowerCase().includes(term.toLowerCase())
                )
              );
            }

            if (isMachineMatch || matchingSensors.length > 0) {
              machinesAcc.push({
                ...machine,
                sensors: isMachineMatch ? machine.sensors : matchingSensors
              });
            }

            return machinesAcc;
          }, []);
        }

        if (isLineMatch || matchingMachines.length > 0) {
          acc.push({
            ...node,
            machines: isLineMatch ? node.machines : matchingMachines
          });
        }

        return acc;
      }, []);
    };

    const updatedTreeData = searchTree(treeView.production_lines || [], searchTermsArray);
    setFilteredTreeData(updatedTreeData);
  }, [selectedProductLines, selectedMachines, selectedSensors, treeView.production_lines]);

  const handleProductLineChange = (event, value) => {
    setSelectedProductLines(value);
    setErrors({});
  };

  const handleMachineChange = (event, newValue) => {
    setSelectedMachines(newValue);
  };

  const handleSensorChange = (event, newValue) => {
    setSelectedSensors(newValue);
  };

  const handleSelectionChange = items => {
    if (!items || !Array.isArray(items)) return;

    const usedColors = new Set();

    const flattenedChildren = items.flatMap((item, itemIndex) =>
      (item.childrenNames || []).map((child, childIndex) => ({
        ...child,
        machineID: item.machineID,
        productLine: item.productLine,
        uniqueIndex: itemIndex * 100 + childIndex
      }))
    );

    const extractedData = flattenedChildren.map(child => {
      const nextIndex = child.uniqueIndex;
      let color;
      do {
        color = generateColor(nextIndex);
      } while (usedColors.has(color));

      usedColors.add(color);

      return {
        machine_id: child.machineID,
        type: child.type,
        auto_scale: true,
        line_color: generateColor(nextIndex),
        line_style: 'LINE_STYLE_SOLID',
        line_thickness: 1,
        max_scale: child.max_scale || null,
        min_scale: child.min_scale || null,
        is_custom: child.is_custom || false
      };
    });

    setSelectedItems(extractedData);
  };

  const validateFields = () => {
    const validationErrors = {};
    if (!templateIdentification) {
      validationErrors.templateIdentification = t(
        'dialog.add_new_template.validation.template_identification_required'
      );
    }
    if (!description) {
      validationErrors.description = t('dialog.add_new_template.validation.description_required');
    }
    return validationErrors;
  };

  const handleInputChange = (fieldName, value) => {
    if (fieldName === 'templateIdentification') {
      setTemplateIdentification(value);
    } else if (fieldName === 'description') {
      setDescription(value);
    }

    setErrors(prevErrors => ({
      ...prevErrors,
      [fieldName]: ''
    }));
  };

  const handleNext = () => {
    if (selectedItems.length < 2) {
      setAlertSelectSensors(true);
    } else {
      setAlertSelectSensors(false);
      setActiveStep(prev => prev + 1);
    }
  };

  const handleBack = () => setActiveStep(prev => prev - 1);

  const handleSave = () => {
    const validationErrors = validateFields();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }

    const values = {
      favorite: false,
      machine_id: machineParam?.id,
      name: templateIdentification,
      description,
      period_type: filterTime,
      sensors: selectedItems,
      tmpl_access_type: accessType
    };
    dispatch(createGraphsTemplate(values));
    handleCloseDialog();
  };

  const content = (
    <Box sx={{ minWidth: { sm: '320px', md: '600px', lg: '800px' } }}>
      {alertSelectSensors && (
        <Grid item xs={12}>
          <Alert severity="error">{t('dialog.add_new_template.alert_to_select_sensors')}</Alert>
        </Grid>
      )}
      <Steps activeStep={activeStep} steps={steps} />
      {activeStep === 0 && (
        <Step1
          selectedProductLines={selectedProductLines}
          handleProductLineChange={handleProductLineChange}
          productionLinesObj={productionLinesObj}
          clear={clear}
          setClear={setClear}
          selectedMachines={selectedMachines}
          handleMachineChange={handleMachineChange}
          machines={machines}
          selectedSensors={selectedSensors}
          handleSensorChange={handleSensorChange}
          sensors={sensors}
          filteredTreeData={filteredTreeData}
          selectedItems={selectedItems}
          handleSelectionChange={handleSelectionChange}
        />
      )}
      {activeStep === 1 && (
        <Step2
          handleInputChange={handleInputChange}
          templateIdentification={templateIdentification}
          errors={errors}
          filterTime={filterTime}
          setFilterTime={setFilterTime}
          periodObject={periodObject}
          description={description}
          accessType={accessType}
          setAccessType={setAccessType}
        />
      )}
    </Box>
  );

  return (
    <NewDialog
      open={open}
      handleClose={handleCloseDialog}
      handleCancel={handleCloseDialog}
      handleOk={handleCloseDialog}
      title={t('dialog.add_new_template.title')}
      subTitle={t('dialog.add_new_template.subtitle')}
      content={content}
      displayActionBar="flex"
      justifyContentActionBar="space-between"
      paddingXContent={{ sm: '1rem', md: '2rem', lg: '3rem' }}
      actionBar={
        <ActionBar
          handleCloseDialog={handleCloseDialog}
          activeStep={activeStep}
          handleBack={handleBack}
          steps={steps}
          handleSave={handleSave}
          handleNext={handleNext}
        />
      }
    />
  );
}

AddNewTemplate.propTypes = {
  open: T.bool.isRequired,
  handleCloseDialog: T.func.isRequired,
  machine: T.shape().isRequired
};
