import React from 'react';
import T from 'prop-types';
import { Grid, InputAdornment } from '@mui/material';
import { FormElements, NotifyIcon } from 'web-components';
import { getMillSensor } from 'attrs/sensorType';
import { useTranslation } from 'react-i18next';
import { CustomTreeView } from '../../../../../../components/CustomTreeView';

const Step1 = ({
  selectedProductLines,
  handleProductLineChange,
  productionLinesObj,
  clear,
  setClear,
  selectedMachines,
  handleMachineChange,
  machines,
  selectedSensors,
  handleSensorChange,
  sensors,
  filteredTreeData,
  selectedItems,
  handleSelectionChange
}) => {
  const { t } = useTranslation();
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <FormElements.MultiSelect
            label={t('dialog.add_new_template.filter_by_product_line')}
            name="product_line"
            id="product_line"
            bgcolor="#F7F7FA"
            fullWidth
            value={selectedProductLines}
            setFieldValue={handleProductLineChange}
            options={productionLinesObj.length > 0 && productionLinesObj.map(item => ({ title: item, value: item }))}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end" style={{ position: 'absolute', right: '12px', bottom: '22px' }}>
                  <NotifyIcon iconName="filterOutlined" fontSize="default" color="primary" />
                </InputAdornment>
              )
            }}
            data-selector="dialog-add-new-template-filter-product-line-field"
            clear={clear}
            setClear={setClear}
            sx={{
              mt: { md: 1 },
              mb: { md: 1 }
            }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <FormElements.MultiSelect
            label={t('dialog.add_new_template.filter_by_machine')}
            name="filter_machine"
            id="filter_machine"
            bgcolor="#F7F7FA"
            fullWidth
            multiple
            value={selectedMachines}
            setFieldValue={handleMachineChange}
            options={machines.length > 0 && machines.map(item => ({ id: item.id, title: item.name, value: item.name }))}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end" style={{ position: 'absolute', right: '12px', bottom: '22px' }}>
                  <NotifyIcon iconName="filterOutlined" fontSize="default" color="primary" />
                </InputAdornment>
              )
            }}
            data-selector="dialog-add-new-template-filter-product-line-field"
            clear={clear}
            setClear={setClear}
            sx={{
              mt: { md: 1 },
              mb: { md: 1 }
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <FormElements.MultiSelect
            label={t('dialog.add_new_template.filter_by_sensor')}
            name="filter_sensor"
            id="filter_sensor"
            bgcolor="#F7F7FA"
            fullWidth
            multiple
            value={selectedSensors}
            setFieldValue={handleSensorChange}
            options={
              sensors.length > 0 &&
              sensors.map(sensor => {
                const millSensor = getMillSensor(sensor?.type);
                const sensorName = t(millSensor?.name || sensor?.custom_sensor_name);
                return {
                  title: sensorName,
                  value: sensor.type
                };
              })
            }
            InputProps={{
              endAdornment: (
                <InputAdornment position="end" style={{ position: 'absolute', right: '12px', bottom: '22px' }}>
                  <NotifyIcon iconName="filterOutlined" fontSize="default" color="primary" />
                </InputAdornment>
              )
            }}
            data-selector="dialog-add-new-template-filter-product-line-field"
            clear={clear}
            setClear={setClear}
            sx={{
              mt: { md: 1 },
              mb: { md: 1 }
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <CustomTreeView
            rawData={filteredTreeData}
            selectedItems={selectedItems}
            onSelectionChange={handleSelectionChange}
          />
        </Grid>
      </Grid>
    </>
  );
};

export { Step1 };

const sensorType = T.oneOfType([
  T.shape({
    custom_sensor_icon: T.string,
    custom_sensor_name: T.string,
    is_custom: T.bool,
    type: T.string
  }),
  T.shape({
    is_custom: T.bool,
    type: T.string
  })
]);

Step1.propTypes = {
  selectedProductLines: T.arrayOf(
    T.shape({
      title: T.string,
      value: T.string
    })
  ).isRequired,
  handleProductLineChange: T.func.isRequired,
  productionLinesObj: T.arrayOf(T.string).isRequired,
  clear: T.bool.isRequired,
  setClear: T.func.isRequired,
  selectedMachines: T.arrayOf(
    T.shape({
      id: T.string,
      title: T.string,
      value: T.string
    })
  ).isRequired,
  handleMachineChange: T.func.isRequired,
  machines: T.arrayOf(
    T.shape({
      id: T.string,
      name: T.string
    })
  ).isRequired,
  selectedSensors: T.arrayOf(
    T.shape({
      title: T.string,
      value: T.string
    })
  ).isRequired,
  handleSensorChange: T.func.isRequired,
  sensors: T.arrayOf(sensorType).isRequired,
  filteredTreeData: T.arrayOf(
    T.shape({
      name: T.string,
      machines: T.arrayOf(
        T.shape({
          id: T.string,
          name: T.string,
          sensors: T.arrayOf(sensorType)
        })
      )
    })
  ).isRequired,
  selectedItems: T.arrayOf(
    T.shape({
      auto_scale: T.bool,
      is_custom: T.bool,
      line_color: T.string,
      line_style: T.string,
      line_thickness: T.number,
      machine_id: T.string,
      max_scale: T.number,
      min_scale: T.number
    })
  ).isRequired,
  handleSelectionChange: T.func.isRequired
};
